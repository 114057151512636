import { Environment } from '../entities/common'

interface IEnvironmentConfig {
  environment: Environment
  api_base: string
  auth_url: string
  sphinx_api_key: string
}

export const environments: { [index: string]: Environment } = {
  localhost: Environment.local,
  'sphinx-dev.int.thomsonreuters.com': Environment.dev,
  'sphinx-qa.int.thomsonreuters.com': Environment.test,
  'sphinx-qaauto.int.thomsonreuters.com': Environment.sit,
  'sphinx-uat.thomsonreuters.com': Environment.uat,
  'sphinx.thomsonreuters.com': Environment.prod,
}

const configs: { [index in Environment]: IEnvironmentConfig } = {
  [Environment.local]: {
    environment: Environment.local,
    api_base: 'https://api.sphinx-test.thomsonreuters.com',
    auth_url: 'https://sphinx-dev.int.thomsonreuters.com/auth/',
    sphinx_api_key: 'yhAbBN4G878cYEjVVSfus2cO4yCKz7yW9stY3ilj',
  },
  [Environment.dev]: {
    environment: Environment.dev,
    api_base: 'https://api.sphinx-test.thomsonreuters.com',
    auth_url: 'https://sphinx-dev.int.thomsonreuters.com/auth/',
    sphinx_api_key: 'yhAbBN4G878cYEjVVSfus2cO4yCKz7yW9stY3ilj',
  },
  [Environment.test]: {
    environment: Environment.test,
    api_base: 'https://api.sphinx-test.thomsonreuters.com',
    auth_url: 'https://sphinx-qa.int.thomsonreuters.com/auth/',
    sphinx_api_key: 'yhAbBN4G878cYEjVVSfus2cO4yCKz7yW9stY3ilj',
  },
  [Environment.sit]: {
    environment: Environment.sit,
    api_base: 'https://api.sphinx-test.thomsonreuters.com',
    auth_url: 'https://sphinx-qaauto.int.thomsonreuters.com/auth/',
    sphinx_api_key: 'yhAbBN4G878cYEjVVSfus2cO4yCKz7yW9stY3ilj',
  },
  [Environment.uat]: {
    environment: Environment.uat,
    api_base: 'https://api.sphinx-uat.thomsonreuters.com',
    auth_url: 'https://sphinx-uat.thomsonreuters.com/auth/',
    sphinx_api_key: 'bxZdg2shM22a5SQKP8Jzc739QHZwtbOC8J4uxGwU',
  },
  [Environment.prod]: {
    environment: Environment.prod,
    api_base: 'https://api.sphinx.thomsonreuters.com',
    auth_url: 'https://sphinx.thomsonreuters.com/auth/',
    sphinx_api_key: 'PYSkBSyDdB1m3eAXny6Qv6JewNg5KKnva0KqMYx1',
  },
}

const hostname = window.location.hostname

export const environment = environments[hostname] || Environment.dev

export default configs[environment]
