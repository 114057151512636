import { createStorage, setupFeatureFlag } from '@sphinx/feature-flags'
import InitialConfig, { FeaturesConfig } from '../../config/feature'

const { Feature, Provider, useFeature, featureMethods } = setupFeatureFlag<FeaturesConfig>()

const storage = createStorage(sessionStorage, { key: 'sphinx_features' })

const FeaturesProvider: React.FC = ({ children }) => {
  return (
    <Provider features={InitialConfig} storage={storage}>
      {children}
    </Provider>
  )
}

export { FeaturesProvider, Feature, useFeature, featureMethods }
