import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const ApplicationWrap = styled.div`
  height: 100vh;
  max-width: 100vw;
`

export const GlobalStyles = css`
  body {
    margin: 0;
  }
`
