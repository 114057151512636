import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import config from '../config/environment'
import tokenService from '../services/token.service'
import { authService } from '../services/auth.service'
import { httpService } from '../services/http.service'

interface AuthVerificationResult {
  statusMessage: string
}

const verifyToken = (): Promise<AuthVerificationResult> =>
  httpService.get(`${config.api_base}/auth/v1/verify`)

export const useAuth = () => {
  const { status, refetch: validate } = useQuery('auth', verifyToken, {
    enabled: false,
    retry: false,
  })
  const [authenticated, setAuthenticated] = useState(false)
  const token = tokenService.get()

  useEffect(() => {
    if (!token) {
      authService.authenticate()
    } else {
      validate()
    }
  }, [validate, token])

  useEffect(() => {
    if (['idle', 'loading'].includes(status)) {
      return
    }

    if (status === 'success') {
      setAuthenticated(true)
      return
    }

    if (status === 'error') {
      authService.invalidate()
      authService.authenticate()
    }
  }, [status, token])

  return {
    authenticated,
    token,
  }
}
