import config from '../config/environment'
import { IAuthStrategy } from '../interfaces'
import locationService from './location.service'

const defaultAuthenticate = () => {
  locationService.redirect(
    `${config.auth_url}?redirect=${encodeURIComponent(locationService.getCurrentHref())}`
  )
}

export const defaultAuthStrategy: IAuthStrategy = { authenticate: defaultAuthenticate }
