import { Environment } from '../entities/common'
import { environment } from './environment'

export enum Features {
  amtToggle = 'amtToggle',
}

export type FeaturesConfig = { [key in Features]?: boolean }

const configs: { [index in Environment]: FeaturesConfig } = {
  [Environment.local]: {
    amtToggle: true,
  },
  [Environment.dev]: {
    amtToggle: true,
  },
  [Environment.test]: {
    amtToggle: true,
  },
  [Environment.sit]: {
    amtToggle: true,
  },
  [Environment.uat]: {
    amtToggle: true,
  },
  [Environment.prod]: {
    amtToggle: true,
  },
}

export default configs[environment]
