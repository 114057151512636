import * as React from 'react'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import { LoadingBar } from '@sphinx/react-components'
import { ApplicationWrap, GlobalStyles } from './App.style'
import { useAuth } from '../../hooks/auth'
import { Routes } from './App.routes'
import { withNotifications } from '../Notifications/notifications.hoc'
import { FeaturesProvider } from '../feature/feature.setup'

const StyledLoadingBar = styled(LoadingBar)`
  position: fixed;
`

export const App: React.FC = withNotifications(() => {
  const { authenticated } = useAuth()

  if (!authenticated) {
    return (
      <>
        <Global styles={GlobalStyles} />
        <StyledLoadingBar durationMs={5 * 1000} />
      </>
    )
  }

  return (
    <FeaturesProvider>
      <ApplicationWrap>
        <Global styles={GlobalStyles} />
        <React.Suspense fallback={<StyledLoadingBar durationMs={3 * 1000} />}>
          <Routes />
        </React.Suspense>
      </ApplicationWrap>
    </FeaturesProvider>
  )
}, 'global')
