import * as React from 'react'
import { NotificationType, CloseOutlineIcon } from '@sphinx/react-components'
import {
  Notification,
  RecoverableError,
  NotificationsContextValue,
} from './notifications.interface'
import { getNotificationsContext } from './notifications.context'
import { Pages } from '../../constants/pages'

export function withNotifications<P>(
  Component: React.ComponentType<P>,
  pageId: Pages | 'global'
): React.FC<P> {
  return function(props: P) {
    const [notifications, setNotifications] = React.useState<Notification[]>([])

    const showNotification = React.useCallback(
      (notification: Notification) => {
        setNotifications([
          ...notifications.filter(item => item.id !== notification.id),
          notification,
        ])
      },
      [notifications, setNotifications]
    )

    const showRecoverableError = React.useCallback(
      (notification: RecoverableError) =>
        showNotification({
          pageId,
          id: notification.id || Date.now().toString(),
          message: notification.message,
          type: NotificationType.error,
          icon: CloseOutlineIcon,
          onClose: () => {},
          actions: [
            {
              title: 'Try again',
              action: notification.onRecover,
              'data-e2e': 'error-notification__retry',
            },
          ],
        }),
      [showNotification]
    )

    const closeNotification = React.useCallback(
      (notificationId: string) => {
        setNotifications(notifications.filter(item => item.id !== notificationId))
      },
      [notifications, setNotifications]
    )

    const contextValue: NotificationsContextValue = React.useMemo(
      () => ({
        pageId,
        notifications,
        showNotification,
        showRecoverableError,
        closeNotification,
      }),
      [notifications, showNotification, showRecoverableError, closeNotification]
    )

    const NotificationsContext = getNotificationsContext(pageId)

    return (
      <NotificationsContext.Provider value={contextValue}>
        <Component {...props} />
      </NotificationsContext.Provider>
    )
  }
}
