import { embeddedTokenService } from '@sphinx/leon-services'
import { defaultAuthStrategy } from './default-authenticate.strategy'
import tokenService from './token.service'
import locationService from './location.service'
import { IAuthStrategy } from '../interfaces'

const embeddedAuthenticate = () =>
  embeddedTokenService
    .getToken()
    .then(token => {
      if (token) {
        tokenService.save(token)
        locationService.reload()
      } else {
        defaultAuthStrategy.authenticate()
      }
    })
    .catch(() => defaultAuthStrategy.authenticate())

export const isEmbeddedAuthAvailable = async (): Promise<boolean> =>
  embeddedTokenService.isAvailable().catch(() => false)

export const embeddedAuthStrategy: IAuthStrategy = { authenticate: embeddedAuthenticate }
